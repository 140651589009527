<template>
  <AppModal
    v-model="model"
    :title="$t('PageSendMoneySummary.EditAmountHeader').value"
    @hide="reset"
  >
    <template #header="{ dismiss }">
      <AppModalHeader>
        <template #default>{{ $t('PageSendMoneySummary.EditAmountHeader').value }}</template>
        <template #right>
          <AppBackButton
            analytics-name="send-summary-edit-amount-back"
            :disabled="isLoading"
            @click="dismiss"
          />
        </template>
      </AppModalHeader>
    </template>

    <form id="editAmountForm" @submit.prevent="submit">
      <AppInputAmountCurrency
        v-model="form.amountFrom"
        analytics-name="send-summary-edit-amount-input"
        :loading="isLoading"
        :label="$t('PageSendMoneySummary.EditAmountSendLabel').value"
        :currency.sync="form.currencyFrom"
        :currencies="currenciesFrom"
        :locale="userLocale"
        :validation="validation.amountFrom"
      >
      </AppInputAmountCurrency>
    </form>

    <template #footer>
      <AppModalFooter>
        <AppButton
          type="submit"
          form="editAmountForm"
          analytics-name="send-summary-edit-amount-confirm"
          :disabled="isDisabled"
        >
          {{ $t('PageSendMoneySummary.EditAmountConfirmButton').value }}
        </AppButton>
      </AppModalFooter>
    </template>
  </AppModal>
</template>

<script>
import { reactive, computed, toRef, watch } from '@vue/composition-api'
import { usePromiseLazy, useValidation } from 'vue-composable'

import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

import { useI18nStore, useDeviceStore, useAnalyticsStore, useSendMoneyStore } from '@galileo/stores'

import {
  useVModel,
  AppModal,
  AppModalHeader,
  AppModalFooter,
  AppBackButton,
  AppInputAmountCurrency,
  AppButton,
} from '@oen.web.vue2/ui'
import getValueRequiredValidation from '@galileo/utilities/validations.utility'

export default {
  name: 'SendMoneySummaryEditModal',
  emits: ['input', 'loading'],
  components: {
    AppModal,
    AppModalHeader,
    AppModalFooter,
    AppBackButton,
    AppInputAmountCurrency,
    AppButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const deviceStore = useDeviceStore()
    const analyticsStore = useAnalyticsStore()
    const sendMoneyStore = useSendMoneyStore()
    const valueRequired = getValueRequiredValidation()
    const currenciesFrom = computed(() => sendMoneyStore.getCombinedAvailableCurrenciesFrom)
    const userLocale = computed(() => deviceStore.getLocale)

    const initialValues = reactive({
      amountFrom: sendMoneyStore.getAmountFrom(false),
      currencyFrom: sendMoneyStore.getCurrencyFrom,
    })

    const form = reactive({
      amountFrom: computed({
        get: () => sendMoneyStore.getAmountFrom(false),
        set: async (amount) => {
          sendMoneyStore.form.shouldCalcAmountFrom = false
          sendMoneyStore.form.amountFrom = amount || 0
          await calculator.exec()
        },
      }),
      currencyFrom: computed({
        get: () => sendMoneyStore.getCurrencyFrom,
        set: async (currency) => {
          sendMoneyStore.form.currencyFrom = currency
          await calculator.exec()
        },
      }),
    })

    const validation = useValidation({
      amountFrom: {
        $value: toRef(form, 'amountFrom'),
        isInvalid: {
          $validator() {
            return (
              calculator &&
              !calculator.error?.message &&
              //Show only 200 and 400 input errors
              (calculator.error?.status !== 200 || calculator.error?.status !== 400)
            )
          },
          $message: computed(() => calculator.error?.message),
        },
        valueRequired,
      },
    })

    const calculator = reactive(
      usePromiseLazy(
        async ({ debounceTime, updateResult = false } = {}) =>
          await sendMoneyStore.calculate({
            summary: true,
            debounceTime,
            updateResult,
          })
      )
    )

    const submit = async () => {
      // Recalculate on submit to commit results to store
      await calculator.exec({
        debounceTime: 0,
        updateResult: true,
      })

      analyticsStore.track({
        event: SEGMENT_EVENTS.TRANSACTION_EDIT,
        traits: {
          location: SEGMENT_LOCATIONS.SEND_MONEY,
        },
      })
      if (calculator.result) {
        // Update initial values before hiding modal
        initialValues.currencyFrom = form.currencyFrom
        initialValues.amountFrom = form.amountFrom
        // Hide modal
        emit('input', false)
      }
    }

    watch(
      () => calculator.loading,
      (val) => emit('update:loading', val)
    )

    const isDisabled = computed(() => {
      return (
        validation.$anyInvalid ||
        !form.amountFrom ||
        form.amountFrom === 0 ||
        form.amountFrom === initialValues.amountFrom ||
        calculator.loading ||
        calculator.error
      )
    })

    const reset = () => {
      sendMoneyStore.form.currencyFrom = initialValues.currencyFrom
      sendMoneyStore.form.amountFrom = initialValues.amountFrom
      calculator.error = null
    }

    return {
      $t,
      model,
      form,
      validation,
      currenciesFrom,
      userLocale,
      isLoading: computed(() => calculator.loading),
      isDisabled,
      submit,
      reset,
    }
  },
}
</script>

<style scoped></style>
