<template>
  <div>
    <AppCard v-if="!accountIsRetricted" class="summary-card">
      <template #header>
        <XeStepperCardHeader
          :title="summaryPageTitle"
          class="summary-header"
          analytics-name="xe-send-money-summary"
        >
          <template #right>
            <AppButton
              v-if="!isO2OTransfer"
              theme="icon"
              class="edit-button"
              analytics-name="send-summary-edit"
              @click="editDetails"
            >
              <AppIcon :name="$t('PageSendMoneySummary.ButtonEditAmount').value">
                <IconEdit />
              </AppIcon>
            </AppButton>
          </template>
        </XeStepperCardHeader>
      </template>

      <AppSpinnerBig inline :loading="initialLoading" />

      <Transition name="fade">
        <form v-if="!initialLoading" id="sendMoneySummary" @submit.prevent="submit">
          <div>
            <AppDescriptionList
              :class="{
                'no-border-bottom': activePromotion,
                first: true,
              }"
            >
              <div
                v-if="(rateChanged || shouldDisplayRateChangedAlert) && !isO2OTransfer"
                class="rate-changed"
              >
                <SendMoneyFeeListItem
                  :title="'⚠️ ' + $t('PageSendMoneySummary.RateChangText').value"
                  :loading="false"
                >
                </SendMoneyFeeListItem>
              </div>
              <div v-if="!isAwaitingBalance && isO2OTransfer">
                <AppAlert theme="gray" class="mb-3">
                  <p>
                    <IconExclamationMark class="message-icon" />
                    {{ $t('PageSendMoneySummary.PaymentCollected').value }}
                  </p>
                </AppAlert>
              </div>
              <div v-if="paymentMethod === 'CreditCard' && isAwaitingBalance">
                <AppAlert theme="gray" class="mb-3">
                  <p>
                    <IconWarningYellow class="message-icon" />
                    {{ $t('PageSendMoneySummary.CardFeeWarning').value }}
                  </p>
                </AppAlert>
              </div>
              <SendMoneySummarySendingAmount :loading="isUpdatingAmount" />
              <EllipsisWrapper :value="recipientName" no-resize>
                <SendMoneySummaryRecipientGets :loading="isUpdatingAmount" />
              </EllipsisWrapper>
              <SendMoneySummaryRates :loading="isUpdatingAmount" />

              <AppDescriptionListItem v-if="getPaymentDate">
                <template #title>{{ $t('PageSendMoneySummary.PaymentDate').value }}</template>
                <!-- needs to implement -->
                <template #content>{{ getPaymentDate }}</template>
              </AppDescriptionListItem>

              <AppDescriptionListItem v-if="isMobileWallet">
                <template #title>{{
                  $t('PageSendMoneySummary.MobileWalletTitile').value
                }}</template>
                <template #content>{{ mobileWalletProvider.name }}</template>
              </AppDescriptionListItem>
              <!-- Cash pickup info -->
              <AppDescriptionListItem v-if="isCashPayout">
                <template #title>{{ $t('ComponentCashPickupLocation.title').value }}</template>
                <template #content>
                  <SendMoneyCashPickupLocation
                    :country="countryTo"
                    :currency="currencyTo"
                    :cityName="recipient.recipientCity || recipient.addressLine2"
                    :stateName="recipient.recipientState"
                    :agent="getAgent"
                    :agentLocation="getAgentLocation"
                  />
                </template>
              </AppDescriptionListItem>
              <!-- end of Cash pickup info -->

              <SendMoneyTransferFees
                :title="$t('PageSendMoneySummary.FeesText').value"
                :loading="isUpdatingAmount"
                :show-info="false"
              />
            </AppDescriptionList>
            <!-- Promo code  -->
            <SendMoneyPromoCode v-if="activePromotion" />
            <AppDescriptionList>
              <AppList>
                <AppListItem
                  :class="{
                    'payment-method-item': true,
                    'bank-logos-visible': showBankLogos,
                  }"
                >
                  <template #figure>
                    <slot name="figure">
                      <AppFigure class="xe-figure" theme="gray">
                        <Component
                          v-if="paymentIcon"
                          class="payment-method-icon"
                          :is="paymentIcon"
                        />
                      </AppFigure>
                    </slot>
                  </template>
                  <template #left>
                    <slot name="left">
                      <AppListItemTitle
                        v-if="paymentMethodTitle === 'Interac'"
                        class="interac-wrapper"
                      >
                        <p class="pm-title">
                          {{ paymentMethodTitle }}
                        </p>
                        <IconInterac />
                      </AppListItemTitle>
                      <AppListItemTitle class="pm-title" v-else>
                        {{ paymentMethodTitle }}
                      </AppListItemTitle>
                      <AppListItemCaption>{{ paymentMethodCaption }}</AppListItemCaption>
                      <BankLogos v-if="showBankLogos" />
                    </slot>
                  </template>
                </AppListItem>
              </AppList>

              <SendMoneyTransferTotal
                :title="
                  isAwaitingBalance
                    ? $t('PageSendMoneySummary.TotalText').value
                    : $t('PageSendMoneySummary.AmountPaidText').value
                "
                :loading="isUpdatingAmount"
                analytics-name="send-money-summary-total"
              />
            </AppDescriptionList>
            <AppDescriptionList />
          </div>
          <div v-if="!isO2OTransfer">
            <SendMoneySummaryDisclaimer />
          </div>

          <div v-if="!isO2OTransfer" class="tips">
            <AppLink class="tipsButton" @click="showTips">
              {{ $t('PageSendMoneySummary.Tips').value }}
            </AppLink>
          </div>
          <AppButton
            v-if="mq.current !== 'xs'"
            class="submit-button--full-width"
            analytics-name="send-summary-submit"
            type="submit"
            :disabled="isSendBtnDisabled"
            :loading="isProcessingOrder"
          >
            <p v-if="!isO2OTransfer">
              {{ $t('PageSendMoneySummary.ButtonSend').value }}
            </p>
            <p v-if="isO2OTransfer">
              {{ $t('PageSendMoneySummary.ButtonSendContinue').value }}
            </p>
          </AppButton>
        </form>
      </Transition>

      <!--Submit-->
      <template v-if="mq.current === 'xs'" #footer>
        <AppCardFooter>
          <AppButton
            analytics-name="send-summary-submit"
            type="submit"
            form="sendMoneySummary"
            :disabled="isSendBtnDisabled"
            :loading="isProcessingOrder"
          >
            {{ $t('PageSendMoneySummary.ButtonSend').value }}
          </AppButton>
        </AppCardFooter>
      </template>
    </AppCard>
    <AccountRestricted v-else />

    <!-- Processing time dialog -->
    <SendMoneyProcessingTimesDialog v-model="processingTimeDialog" />

    <!-- Edit sending amount modal -->
    <SendMoneySummaryEditModal v-model="showEditAmountModal" :loading.sync="isUpdatingAmount" />

    <!-- Security code dialog -->
    <CardCVVInputModal
      v-if="isCardPaymentMethod && showSecurityCodeDialog"
      v-model="showSecurityCodeDialog"
      :title="paymentMethodText"
      :masked-account-number="paymentMethodNumber"
      :loading="isProcessingOrder"
      @submit="submitSecurityCode"
      :payment-step="false"
    />

    <!-- Tips dialog -->
    <AppDialog :value="tipsDialog" class="send-summary-tips-modal-wrapper">
      <template #header>
        <AppModalHeader class="send-summary-tips-header">
          <template #right>
            <AppBackButton
              class="send-summary-tips-modal-back absolute"
              analytics-name="send-summary-tips-modal-back-right"
              @click="hideTips"
              name=" "
            />
          </template>
        </AppModalHeader>
      </template>

      <div class="flex flex-col pl-6 pr-6 mb-6">
        <div class="flex justify-center">
          <IconCheckmarkBig />
        </div>
        <h1 class="type-h1 mb-6 mt-4 text-center px-10">
          {{ $t('PageSendMoneySummary.TipsMoneySafetyTitle').value }}
        </h1>
        <div class="send-summary-tips-body">
          <h2 class="send-summary-tips-points">
            {{ $t('PageSendMoneySummary.TipsMoneySafetyPoint1').value }}
          </h2>
          <p class="send-summary-tips-points-info">
            {{ $t('PageSendMoneySummary.TipsMoneySafetyPoint1Info').value }}
          </p>
          <h2 class="send-summary-tips-points">
            {{ $t('PageSendMoneySummary.TipsMoneySafetyPoint2').value }}
          </h2>
          <p class="send-summary-tips-points-info">
            {{ $t('PageSendMoneySummary.TipsMoneySafetyPoint2Info').value }}
          </p>
          <AppLink class="tipsButton" @click="onMoneySafeHelpDesk">
            {{ $t('PageSendMoneySummary.TipsMoneySafetyPoint3').value }}
          </AppLink>
        </div>
      </div>
    </AppDialog>

    <InformationAppModal :value="showPendingOnfido" icon="hourglass">
      <h1 class="title">{{ $t('PageOnfido.PendingTitle').value }}</h1>
      <p>
        {{ $t('PageOnfido.PendingText').value }}
      </p>

      <template #footer>
        <AppButton @click="pendingOk">{{ $t('PageOnfido.ButtonPendingContinue').value }}</AppButton>
      </template>
    </InformationAppModal>
  </div>
</template>

<script>
import { reactive, ref, onBeforeMount, computed } from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'
import { useRouter } from '@galileo/composables/useRouter'
import { getGoogleMapLink } from '@galileo/composables/useTransferHelpers'
import { SendMoneyFeeListItem } from '@galileo/components/Views/SendMoney/Molecules'
import SendMoneyProcessingTimesDialog from '@galileo/components/Views/SendMoney/SendMoneyProcessingTimesDialog'
import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'
import CardCVVInputModal from '@galileo/components/Views/PaymentMethod/CardCVVInputModal'
import SendMoneySummaryEditModal from '@galileo/components/Views/SendMoney/SendMoneySummaryEditModal'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import SendMoneyPromoCode from '@galileo/components/Views/SendMoney/SendMoneyPromoCode'
import EllipsisWrapper from '@galileo/components/Views/Recipient/EllipsisWrapper.vue'
import { useBankLogos } from '@galileo/composables/useBankLogos'
import BankLogos from '@galileo/components/Views/SendMoney/SendMoneyBankLogos'

import {
  getSegmentPaymentMethod,
  getSegmentPayoutMethod,
} from '@galileo/composables/useSegmentHelpers'
import { DeliveryMethod, PaymentMethod } from '@galileo/models/Transaction/app'
import {
  SEGMENT_EVENTS,
  SEGMENT_LOCATIONS,
  SEGMENT_PAYOUT_AGENT_TYPE,
} from '@galileo/constants/segmentAnalytics'
import {
  SendMoneySummarySendingAmount,
  SendMoneyTransferFees,
  SendMoneyTransferTotal,
  SendMoneySummaryRates,
  SendMoneySummaryRecipientGets,
  SendMoneySummaryDisclaimer,
  SendMoneyCashPickupLocation,
} from '@galileo/components/Views/SendMoney/Organisms'
import AccountRestricted from '@galileo/components/Views/SendMoney/Amount/AccountRestricted'

import {
  AppAlert,
  AppList,
  AppListItem,
  AppFigure,
  AppListItemTitle,
  AppListItemCaption,
  AppBackButton,
  AppDialog,
  AppModalHeader,
  useMediaQuery,
  AppButton,
  AppLink,
  AppCard,
  AppCardFooter,
  AppDescriptionList,
  AppDescriptionListItem,
  AppSpinnerBig,
  AppIcon,
} from '@oen.web.vue2/ui'

import {
  IconExclamationMark,
  IconVisaBlack,
  IconMasterCardBlack,
  IconVolt,
  IconPayTo,
  IconACH,
  IconBank,
  IconEFT,
  IconInterac,
} from '@galileo/assets/icons/vue'

import { IconMoneyBag, IconCheckmarkBig, IconEdit, IconWarningYellow } from '@oen.web.vue2/icons'

import {
  useI18nStore,
  useAuthStore,
  useAppStore,
  useResourcesStore,
  useAnalyticsStore,
  usePaymentsStore,
  useSendMoneyStore,
  usePromotionStore,
  useCardAccountStore,
  useKYCRefreshCorporateStore,
} from '@galileo/stores'

import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'
import useOpenBanking from '@galileo/composables/useOpenBanking'
import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'

export default {
  name: 'SendMoneySummary',
  components: {
    AppList,
    AppListItem,
    AppFigure,
    AppBackButton,
    AppDialog,
    AppModalHeader,
    AppListItemTitle,
    AppListItemCaption,
    IconWarningYellow,
    AppAlert,
    AppCard,
    AppCardFooter,
    AppDescriptionList,
    AppDescriptionListItem,
    AppButton,
    AppLink,
    SendMoneyFeeListItem,
    AppSpinnerBig,
    AppIcon,
    SendMoneyProcessingTimesDialog,
    IconEdit,
    XeStepperCardHeader,
    CardCVVInputModal,
    SendMoneySummaryEditModal,
    SendMoneySummarySendingAmount,
    SendMoneyTransferFees,
    SendMoneyTransferTotal,
    SendMoneySummaryRates,
    SendMoneySummaryRecipientGets,
    SendMoneySummaryDisclaimer,
    SendMoneyCashPickupLocation,
    AccountRestricted,
    IconExclamationMark,
    EllipsisWrapper,
    InformationAppModal,
    IconVisaBlack,
    IconMasterCardBlack,
    IconACH,
    IconBank,
    IconMoneyBag,
    IconEFT,
    IconCheckmarkBig,
    IconVolt,
    IconPayTo,
    SendMoneyPromoCode,
    IconInterac,
    BankLogos,
  },

  setup() {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const router = useRouter()

    const resourcesStore = useResourcesStore()
    const paymentsStore = usePaymentsStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const sendMoneyStore = useSendMoneyStore()
    const expressPaymentText = $t('SendMoneySummary.VoltPaymentTextSummary').value
    const { isPayTo } = useOpenBanking()
    const promotionStore = usePromotionStore()
    const kycRefreshCorporateStore = useKYCRefreshCorporateStore()

    const activePromotion = promotionStore.getActivePromoData()

    const showPendingOnfido = computed(() => {
      return sendMoneyStore.isVerificationPending
    })

    const isProofOfAddressPending = sendMoneyStore.isProofOfAddressPending

    let accountIsRetricted = ref(false)

    const isO2OTransfer = sendMoneyStore.isO2OContract

    if (!isO2OTransfer) {
      // Change visibility of Verification step
      sendMoneyStore.changeVerificationVisibility(false)
    }

    // Tell the store which step we are on
    sendMoneyStore.setActiveStep(router.currentRoute.path)

    const paymentMethodType = sendMoneyStore.form.paymentMethod

    // Delivery details
    const deliveryMethodText = computed(() => sendMoneyStore.getDeliveryMethodText)
    const mobileWalletProvider = computed(() => sendMoneyStore.mobileWalletProvider)

    let getPaymentDate = computed(() => {
      const deliveryMethods = sendMoneyStore.form.deliveryMethods
      const choosenDeliveryMethod = deliveryMethods.find((method) => method.quote)
      return choosenDeliveryMethod?.quote.time
    })

    const rateChanged = computed(
      () => sendMoneyStore.getRate() !== sendMoneyStore.getConfirmedRate(false)
    )

    const rateChangedSegmentValue = sendMoneyStore.shouldDisplayRateChangedAlert

    const shouldDisplayRateChangedAlert = computed(
      () => sendMoneyStore.shouldDisplayRateChangedAlert || sendMoneyStore.shouldCheckForRateChange
    )

    const isAwaitingBalance = computed(() => sendMoneyStore.isAwaitingBalance)

    // Tell the store which step we are on
    const activeStep = computed(() => sendMoneyStore.activeStep)

    // INIT
    const initialLoading = ref(true)
    const calculate = reactive(
      usePromiseLazy((debounceTime) => sendMoneyStore.calculate({ summary: true, debounceTime }))
    )

    const senderCountry = authStore.userProfile?.country

    const paymentIconFilter = (paymentMethod) => {
      let paymentMethodText = sendMoneyStore.getPaymentMethodText.toLowerCase()

      if (paymentMethod === PaymentMethod.BankAccount.enumName) {
        if (senderCountry === 'CA') {
          return 'icon-EFT'
        } else if (senderCountry === 'NZ' || senderCountry === 'AU') {
          return 'icon-money-bag'
        } else {
          return 'icon-ACH'
        }
      } else if (
        paymentMethod === PaymentMethod.CreditCard.enumName ||
        paymentMethod === PaymentMethod.DebitCard.enumName
      ) {
        //CARD PAYMENT SECTION
        if (paymentMethodText === 'visa debit' || paymentMethodText === 'visa credit') {
          return 'icon-visa-black'
        } else if (
          paymentMethodText === 'mastercard credit' ||
          paymentMethodText === 'mastercard debit'
        ) {
          return 'icon-master-card-black'
        }
      } else if (paymentMethod === PaymentMethod.OpenBanking.enumName) {
        if (isPayTo.value) {
          return 'icon-pay-to'
        } else {
          return 'icon-volt'
        }
      } else {
        return 'icon-bank'
      }
    }

    const paymentIcon = paymentIconFilter(paymentMethodType)

    const summaryPageTitle = ref('')
    onBeforeMount(async () => {
      sendMoneyStore.showVerifyNotSuppliedModal = true
      sendMoneyStore.resetVoltProps()
      sendMoneyStore.resetAdyenProps()

      if (!isO2OTransfer) {
        summaryPageTitle.value = $t('PageSendMoneySummary.PageTitle').value

        await calculate.exec(0)

        await paymentsStore.getPaymentMethods()

        if (calculate.error) {
          appStore.logException('Exception during calculate in summary', calculate.error)
          appStore.messageBoxGenericError()
        } else {
          initialLoading.value = false
        }
      } else {
        summaryPageTitle.value = $t('PageXeStepperCardHeader.ReviewTransfer').value
        initialLoading.value = false
      }
    })

    // Edit transaction
    const editDetails = async () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.TRANSACTION_EDIT,
        traits: {
          location: SEGMENT_LOCATIONS.TRANSACTION_SUMMARY,
        },
      })
      await router.push(
        {
          name: 'SendMoneySummaryEdit',
        },
        true
      )
    }

    const currencyFrom = sendMoneyStore.getCurrencyFrom

    // Recipient details
    const recipientName = computed(() => sendMoneyStore.getRecipientName)
    const recipient = computed(() => sendMoneyStore.getRecipient)

    // Destination details
    const countryTo = computed(() => sendMoneyStore.getCountryTo)
    const currencyTo = computed(() => sendMoneyStore.form.currencyTo)

    // See location link
    const googleMapsURL = computed(() => {
      const deliveryMethodAgentOpenPayment = sendMoneyStore.getDeliveryMethodAgentOpenPayment
      const deliveryMethodAgentLocation = sendMoneyStore.form.deliveryMethodLocation
      const { latitude, longitude } = deliveryMethodAgentLocation || {}

      if (!deliveryMethodAgentOpenPayment) {
        return getGoogleMapLink({ latitude, longitude })
      }
      return null
    })

    // Location link text
    const locationLinkText = computed(() => {
      const deliveryMethod = sendMoneyStore.form.deliveryMethod
      switch (deliveryMethod) {
        case DeliveryMethod.CashPickup.enumName:
          return $t('PageSendMoneySummary.SeeLocationText').value
        case DeliveryMethod.HomeDelivery.enumName:
          return $t('PageSendMoneySummary.SeeDeliveryAgentText').value
        default:
          return null
      }
    })

    // Payment details
    const paymentMethodText = computed(() => sendMoneyStore.getPaymentMethodText)
    const paymentMethodId = computed(() => sendMoneyStore.form.paymentMethodId)
    const paymentMethodNumber = computed(() => sendMoneyStore.form.paymentMethodNumber)

    const paymentMethodTitle = computed(() => {
      let title = sendMoneyStore.form.paymentMethodTitle
      let result = ''
      let splitedTitle = title.split(' ')

      for (var index in splitedTitle) {
        let word = splitedTitle[index]
        let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
        result += capitalized + ' '
      }

      if (paymentMethodType === PaymentMethod.OpenBanking.enumName && isPayTo.value) {
        result = $t('PageSendMoneyAmount.PayToOpenBankingDropdownText').value
      } else if (paymentMethodType === PaymentMethod.Interac.enumName) {
        result = PaymentMethod.Interac.text
      } else if (paymentMethodType === PaymentMethod.OpenBanking.enumName) {
        result = expressPaymentText
      }
      return result
    })

    const paymentMethodCaption = computed(() => sendMoneyStore.form.paymentMethodCaption)

    // Edit amount modal
    const showEditAmountModal = ref(false)
    const isUpdatingAmount = ref(false)
    const showEditAmount = () => {
      showEditAmountModal.value = true
    }

    // Processing times modal
    const processingTimeDialog = ref(false)
    const showProcessingTimeDialog = () => {
      processingTimeDialog.value = true
    }

    // Process order
    const processOrder = reactive(
      usePromiseLazy(() =>
        sendMoneyStore.processOrder({
          transferId: sendMoneyStore.form.transferId,
          replacePage: false,
        })
      )
    )

    const isSendBtnDisabled = computed(() => {
      return (
        initialLoading.value ||
        (recipientName.value.length <= 0 && !isO2OTransfer) ||
        isUpdatingAmount.value
      )
    })

    const showBankLogos = computed(() => {
      return useBankLogos(sendMoneyStore.form.paymentMethod)
    })

    const submit = async () => {
      sendMoneyStore.shouldRefreshQuote = false
      const userProfile = authStore.userProfile
      accountIsRetricted.value = userProfile.customer.accountRestricted

      if (authStore.isCorporateAccount && kycRefreshCorporateStore.isOverdue) {
        kycRefreshCorporateStore.showModal({ isBlockingTransaction: true })
        return
      }

      if (hasValidPaymentMethod.value) {
        if (!isO2OTransfer) {
          trackTransactionSummaryConfirmed()
        } else {
          sendMoneyStore.transactionCreatedSegmentEvent(SEGMENT_EVENTS.REVIEW_TRANSFER)
        }

        sendMoneyStore.setConfirmedRate()
        await processOrder.exec()
        if (processOrder.error) {
          accountIsRetricted.value = sendMoneyStore.accountIsRestricted || accountIsRetricted.value

          if (!accountIsRetricted.value && !showPendingOnfido.value) {
            // store.dispatch('app/messageBoxGenericError')
            router.replace(processOrder.error)
          } else {
            console.log('', processOrder.error)
          }
        } else {
          router.replace(processOrder.result)
        }
      } else {
        showSecurityCodeDialog.value = true
      }
      sendMoneyStore.resetTransferInformation()
    }

    // Security code modal
    const showSecurityCodeDialog = ref(false)
    const isCardPaymentMethod = computed(() => {
      const paymentMethod = sendMoneyStore.form.paymentMethod
      return (
        [PAYMENT_METHODS.CREDIT_CARD, PAYMENT_METHODS.DEBIT_CARD].includes(paymentMethod) &&
        !isO2OTransfer
      )
    })

    const hasValidPaymentMethod = computed(() => {
      if (isCardPaymentMethod.value) {
        const ccv = sendMoneyStore.hasSecurityCode

        if (ccv) {
          return true
        }
        //lets retrieve and set the selected payment method
        const avPaymentMethods = paymentsStore.getPaymentMethodsByType(paymentMethodType)

        const selectedCard = avPaymentMethods.find((card) => card.id === paymentMethodId.value)

        //if we don't have a selected card and it's adyen means we are creating it
        if (!selectedCard && useCardAccountStore().useAdyen) {
          return true
        }

        //setting selected payment method
        paymentsStore.setSelectedPaymentMethod(selectedCard)

        return false
      }
      return true
    })

    const submitSecurityCode = async (securityCode) => {
      sendMoneyStore.form.cardSecurityCode = securityCode

      let analyticsPaymentMethod = getSegmentPaymentMethod(paymentMethodType)

      analyticsStore.track({
        event: SEGMENT_EVENTS.CVV_ENTERED,
        traits: {
          paymentType: analyticsPaymentMethod,
        },
      })
      closeCvv()
      await submit()
    }

    // Consumer fraud
    const fraudInfoURL = computed(() => appStore.getXeAppUrl('fraud-awareness'))

    // Segment
    const origAmount = sendMoneyStore.getAmountFrom(false)

    const paymentMethod = sendMoneyStore.form.paymentMethod
    const deliveryMethod = sendMoneyStore.form.deliveryMethod

    const findCashPayoutType = computed(() => {
      let analyticsPayoutType = ''
      if (deliveryMethod === 'CashPayout') {
        const isOpenPayment = sendMoneyStore.form.isOpenPaymentCountry
        const isAgentOpenPayment = sendMoneyStore.getDeliveryMethodAgentOpenPayment
        if (isOpenPayment) {
          analyticsPayoutType = SEGMENT_PAYOUT_AGENT_TYPE.OPEN_PAYMENT
        } else if (isAgentOpenPayment) {
          analyticsPayoutType = SEGMENT_PAYOUT_AGENT_TYPE.OPEN_NETWORK
        } else {
          analyticsPayoutType = SEGMENT_PAYOUT_AGENT_TYPE.POINT_TO_POINT
        }
        return analyticsPayoutType
      }
      return analyticsPayoutType
    })

    const trackTransactionSummaryConfirmed = async () => {
      const walletProvider =
        deliveryMethodText.value === 'Mobile Wallet' ? mobileWalletProvider.value.name : null

      let promoApplied = false
      let promoCode = ''
      let promoName = ''

      if (activePromotion) {
        promoApplied = promotionStore.activePromotion.promotionCode.valid
        promoCode = promotionStore.activePromotion.promotionCode.code
        promoName = activePromotion.name
      }

      await analyticsStore.track({
        event: SEGMENT_EVENTS.TRANSACTION_SUMMARY_CONFIRMED,
        traits: {
          destination_country: sendMoneyStore.getCountryTo,
          location: SEGMENT_EVENTS.TRANSACTION_SUMMARY_CONFIRMED,
          payoutMethod: getSegmentPayoutMethod(deliveryMethod),
          payout_amount: sendMoneyStore.getAmountTo(false),
          payout_currency: sendMoneyStore.form.currencyTo,
          send_amount: sendMoneyStore.getAmountFrom(false),
          send_currency: sendMoneyStore.getCurrencyFrom,
          sendMethod: getSegmentPaymentMethod(paymentMethod),
          sender_country: authStore.userProfile?.country,
          liquidityManager: sendMoneyStore.getLiquidityManager,
          payoutCountryType: findCashPayoutType.value,
          userType: 'Personal',
          isRiaTransfer: sendMoneyStore.getLiquidityManager === 'Ria',
          sameCurrency: currencyTo.value === currencyFrom,
          rateChanged: sendMoneyStore.didRateChange,
          walletProvider,
          promoApplied,
          promoCode,
          promoName,
        },
      })
    }

    const showTerms = () => {
      const url = resourcesStore.getTermsAndConditionsUrl
      appStore.openWindow(url)
    }

    const tipsDialog = ref(false)
    const showTips = () => {
      tipsDialog.value = true
    }
    const hideTips = () => {
      tipsDialog.value = false
    }

    const onLearnMore = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.PAYING_FOR_YOUR_TRANSFER_BY_CREDIT_CARD,
        article: HelpDesk[HelpDeskProperties.PAYING_FOR_YOUR_TRANSFER_BY_CREDIT_CARD],
      })
    }

    const onMoneySafeHelpDesk = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.HOW_DOES_XE_KEEP_MY_MONEY_AND_INFORMATION_SAFE,
        article: HelpDesk[HelpDeskProperties.HOW_DOES_XE_KEEP_MY_MONEY_AND_INFORMATION_SAFE],
      })
    }

    const isCashPayout = computed(() => {
      return sendMoneyStore.form.deliveryMethod === 'CashPayout'
    })
    const isMobileWallet = computed(() => {
      return sendMoneyStore.form.deliveryMethod === 'MobileWallet'
    })
    const getAgent = computed(() => {
      return sendMoneyStore.form.deliveryMethodAgent
    })
    const getAgentLocation = computed(() => {
      return sendMoneyStore.form.deliveryMethodLocation
    })

    const closeCvv = () => {
      showSecurityCodeDialog.value = false
    }

    const pendingOk = () => {
      sendMoneyStore.isVerificationPending = false
      if (!isProofOfAddressPending) {
        router.replace('/activity')
      } else {
        sendMoneyStore.setIsProofOfAddressPending(false)
      }
    }

    return {
      mq: useMediaQuery(),
      activeStep,
      editDetails,
      initialLoading,
      recipientName,
      recipient,
      countryTo,
      currencyTo,
      deliveryMethodText,
      googleMapsURL,
      locationLinkText,
      paymentMethodText,
      paymentMethodNumber,
      paymentMethodTitle,
      paymentMethodCaption,
      showEditAmountModal,
      isUpdatingAmount,
      showEditAmount,
      processingTimeDialog,
      showProcessingTimeDialog,
      isProcessingOrder: computed(() => processOrder.loading),
      isSendBtnDisabled,
      submit,
      isCardPaymentMethod,
      showSecurityCodeDialog,
      submitSecurityCode,
      $t,
      fraudInfoURL,
      rateChanged,
      paymentIcon,
      showTerms,
      showTips,
      hideTips,
      tipsDialog,
      PaymentMethod,
      onLearnMore,
      isCashPayout,
      getAgent,
      getAgentLocation,
      closeCvv,
      findCashPayoutType,
      accountIsRetricted,
      paymentMethod,
      summaryPageTitle,
      isO2OTransfer,
      getPaymentDate,
      shouldDisplayRateChangedAlert,
      isAwaitingBalance,
      mobileWalletProvider,
      isMobileWallet,
      showPendingOnfido,
      pendingOk,
      onMoneySafeHelpDesk,
      IconCheckmarkBig,
      activePromotion,
      showBankLogos,
    }
  },
}
</script>

<style scoped>
.send-money-promo-code {
  .promo-code-content-wrapper {
    @apply mt-6;
  }
}

.summary-header {
  @apply pl-12 pr-12;
}

form {
  .description-list.first {
    @apply pt-0;
  }
}

::v-deep .terms {
  @apply mb-4 text-secondary-text;

  .terms-heading {
    @apply type-caption mb-2;
    a {
      color: var(--theme-color-primary, theme('colors.blue.default'));
    }
  }
}

.recipients-gets {
  @apply flex justify-end;

  img {
    @apply ml-1;
  }
}

.summary-card {
  ::v-deep .submit-button--full-width {
    @apply block w-full my-6;
  }

  .list {
    @apply mt-0;
  }
}

.tips {
  @apply mt-8 text-center;
}

.tipsButton.link {
  @apply font-medium text-base;
  color: var(--theme-color-primary, theme('colors.blue.default'));
}

.terms-heading {
  @apply font-normal;
}

.termsButton.link {
  @apply font-normal text-sm;
  color: var(--theme-color-primary, theme('colors.blue.default'));
}

::v-deep .list-item-content {
  @apply pt-0;
}

.card-fee-warning {
  @apply px-3 py-2 mb-4 text-secondary-text;
  background-color: #f3f4f6;
  font-size: 14px;
  border-radius: 4px;
}

::v-deep .rate-changed {
  @apply px-3 py-2 mb-4;
  background-color: rgba(254, 250, 242, 1);
  color: rgba(229, 112, 73, 1);
  border-radius: 4px;
  .description-list-item-term-title {
    @apply text-sm font-normal;
  }
}

.tips-list {
  li {
    @apply list-none;
  }
}

.delivery-agent {
  @apply font-bold;
}

::v-deep .send-summary-tips-modal-wrapper {
  .send-summary-tips-points {
    @apply pb-2 font-medium;
    font-size: 15px;
  }

  .send-summary-tips-modal-back {
    @apply mt-0;
    top: 30px;
    right: 30px;
  }

  .send-summary-tips-points-info {
    @apply font-normal pb-6;
    font-size: 15px;
  }

  .send-summary-tips-header {
    @apply pt-0 !important;
  }

  .tipsButton {
    ::v-deep .link-text {
      font-size: 15px;
    }
  }

  .dialog--card.card {
    @apply relative;
    .card-content {
      @apply p-0;
      .card-content-block > div {
        @apply p-0;
      }
    }
  }
}

::v-deep.dialog .overlay-content {
  @apply w-auto;
}

::v-deep .payment-method-item {
  .list-item-figure {
    @apply flex;
  }

  &.bank-logos-visible {
    .list-item-content-flex-container {
      @apply flex items-start;
    }
  }
}

.message-icon {
  @apply inline-block w-5 h-5 mr-1;
}

.payment-method-icon {
  @apply w-6 h-6;
}

.no-border-bottom.description-list {
  @apply border-b-0 pb-0 !important;
}

.interac-wrapper {
  @apply flex items-center;
  svg {
    @apply ml-2;
  }
}

.pm-title {
  @apply font-normal text-base !important;
}
</style>
