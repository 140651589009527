<template>
  <AppModal v-model="model" :title="$t('ComponentSendMoneyProcessingTimesDialog.TitleModal').value">
    <template #header="{ dismiss }">
      <AppModalHeader>
        <h3>
          {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesHeader').value }}
        </h3>

        <template #right>
          <AppBackButton analytics-name="send-money-processing-times-back" @click="dismiss" />
        </template>
      </AppModalHeader>
    </template>
    <div class="processing-times-container">
      <p>
        {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesTitle').value }}
      </p>
      <h3>
        {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesBankAccountTitle').value }}
      </h3>
      <p>
        {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesBankAccountText').value }}
      </p>
      <h3>
        {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesCardTitle').value }}
      </h3>
      <p>
        {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesCardText').value }}
      </p>
      <p>
        {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesCardNote').value }}
      </p>
      <h3>
        {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesCashTitle').value }}
      </h3>
      <p>
        {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesCashText').value }}
      </p>
      <p>
        {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesCashDescription').value }}
      </p>
      <p>
        {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesCashDelayTitle').value }}
      </p>
      <h4>
        {{
          $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesCashDelayCustomerTitle').value
        }}
      </h4>
      <p>
        {{
          $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesCashDelayCustomerText').value
        }}
      </p>
      <h4>
        {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesCashDelayBankTitle').value }}
      </h4>
      <p>
        {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesCashDelayBankText').value }}
      </p>
      <h4>
        {{
          $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesCashDelayReviewTitle').value
        }}
      </h4>
      <p>
        {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesCashDelayReviewText').value }}
      </p>
      <h4>
        {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesCashDelayCashTitle').value }}
      </h4>
      <p>
        {{ $t('ComponentSendMoneyProcessingTimesDialog.ProcessingTimesCashDelayCashText').value }}
      </p>
    </div>
  </AppModal>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

import { useVModel, AppModal, AppModalHeader, AppBackButton } from '@oen.web.vue2/ui'

export default {
  name: 'SendMoneyProcessingTimesDialog',
  emits: ['input'],
  components: {
    AppModal,
    AppModalHeader,
    AppBackButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    return {
      model,
      $t,
    }
  },
}
</script>

<style scoped>
.processing-times-container {
  @apply text-secondary-text;

  > p:not(:last-child) {
    @apply mb-4;
  }
  > h3 {
    @apply type-h3;
  }
  > h4 {
    @apply type-subtitle font-bold;
  }
}
</style>
